import React from 'react'

class StartAnimation extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const homeMenuList = ['Home', 'About', 'Work'].reverse()
    return (
      <div className="start-animation" ref={this.props.initRef}>
        {this.props.landing.map((el, i) => (
          <div className={`slide slide-${i + 1} `} key={i}>
            <div className={`slide-content`}>
              <img
                className="slide-image"
                src={el.image.localFile.childImageSharp.fixed.src}
              />
              <div className="slide-tag">
                <span>{homeMenuList[i]}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default StartAnimation
