import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'
import getPageLinks from '../utils/getPageLinks'
import PageSwitch from '../components/pageSwitch'
import StartAnimation from '../components/startAnimation'
import {
  startDesktopAnimationInit,
  startMobileAnimationInit,
  homeAnimationInit,
  homeAnimationShow,
} from '../animation/timelines'

import { TransitionPortal } from 'gatsby-plugin-transition-link'

import Instagram from '../assets/images/icons/instagram.svg'
import LinkedIn from '../assets/images/icons/linkedin.svg'

const Content = ({ home }) => (
  <section className="intro">
    <div className="intro__top">
      <h1>Cam Richards</h1>
      <h5>User Experience & Product Designer</h5>
    </div>
    <div
      className="intro__content intro__content--desktop"
      dangerouslySetInnerHTML={{ __html: home.acf.introduction }}
    />
    <div
      className="intro__content intro__content--mobile"
      dangerouslySetInnerHTML={{ __html: home.acf.introduction_mobile }}
    />
    <div className="intro__bottom">
      <ul>
        <li className="desktop">
          <a
            href="https://www.instagram.com/groovysnoots/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
        </li>
        <li className="desktop">
          <a
            href="https://au.linkedin.com/in/cameron-richards-b188a194"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </li>
        <li>
          <a href="mailto:richards.cameron68@gmail.com">Get in touch</a>
        </li>
        <li className="mobile">
          <a
            href="https://www.instagram.com/groovysnoots/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Instagram} alt="Instagram" />
          </a>
          <a
            href="https://au.linkedin.com/in/cameron-richards-b188a194"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedIn} alt="LinkedIn" />
          </a>
        </li>
      </ul>
      <p>Use the tabs to the → to explore</p>
    </div>
  </section>
)

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.animationRef = React.createRef()
    this.homePageRef = React.createRef()
    this.state = {
      animationInit: false,
    }
  }

  setAnimationSession() {
    typeof window !== 'undefined' &&
      window.sessionStorage.setItem('AnimationInit', true) &&
      this.setState({
        animationInit: window.sessionStorage.getItem('AnimationInit'),
      })
  }

  async animationInit() {
    const windowWidth =
      typeof window !== 'undefined' && window.innerWidth > 1000

    await this.setState({
      animationInit:
        typeof window !== 'undefined' &&
        window.sessionStorage.getItem('AnimationInit'),
    })

    if (!this.state.animationInit) {
      await homeAnimationInit(this.homePageRef.current)
      windowWidth
        ? await startDesktopAnimationInit(this.animationRef.current)
        : await startMobileAnimationInit(this.animationRef.current)
      await homeAnimationShow(this.homePageRef.current)
      await this.setAnimationSession()
    }
  }

  componentDidMount() {
    this.animationInit()
  }

  render() {
    const { location } = this.props
    const { home } = this.props.data
    return (
      <div>
        <SEO
          title="Cam Richards"
          description="User Experience & Product Designer"
          bodyClass="home"
        />
        {!this.state.animationInit && (
          <StartAnimation
            landing={home.acf.landing}
            initRef={this.animationRef}
          />
        )}
        <div ref={this.homePageRef} className="main-wrap main-wrap-home">
          <div className="page-main-home main">
            <div>
              <Content home={home} />
            </div>
          </div>
          <PageSwitch location={location} />
        </div>
      </div>
    )
  }
}

export const homeQuery = graphql`
  query {
    home: wordpressPage(wordpress_id: { eq: 2 }) {
      title
      slug
      acf {
        introduction
        introduction_mobile
        landing {
          image {
            localFile {
              childImageSharp {
                fixed(width: 1500) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
